import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import Swal from 'sweetalert2'
import { Location } from "@angular/common";
import { LoginService } from '../login.service';
import { AuthService, SocialUser, GoogleLoginProvider } from 'angularx-social-login';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  appVersion = environment.appVersion;
  user: SocialUser;
  password: any;

  signinForm: FormGroup;
  isSubmitted = false;
  isDisabled = true;
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })


  constructor(public formBuilder: FormBuilder, private signinService: LoginService, private location: Location, private authService: AuthService, private router: Router) {
    localStorage.clear();
  }

  ngOnInit() {
    // // Rajdeep
    // this.authService.authState.subscribe((user)=>{
    //   this.user = user;
    // })


    this.signinForm = this.formBuilder.group({
      userid: ['User', []],
      password: ['', []],
    })

    // this.signinForm = this.formBuilder.group({
    //   email: ['', [Validators.required, Validators.email, Validators.minLength(6), Validators.maxLength(50)]],
    //   password: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(20)]],
    // })
  }
  get errorControl() {
    return this.signinForm.controls;
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(x => {
      console.log(x);
      this.user = x;
      if (this.user) {
        let req = {
          "user_id": this.user.email
        }
        this.signinService.signIn(req).subscribe(res => {
          res = JSON.parse(res);
          console.log(res);
          if (res.status == 200) {
            this.Toast.fire({
              icon: 'success',
              title: "User Signed In Successfully!"
            })
            localStorage.setItem('username', JSON.stringify(res.body.user_name));
            localStorage.setItem('companyid', JSON.stringify(res.body.company_code));
            // localStorage.setItem('companyid', JSON.stringify(1630904672));
            localStorage.setItem('userid', JSON.stringify(res.body.user_id));
            this.router.navigate(['/']);
          }

        }, (error) => {
          console.log(error);
          if (error.status == 401) {
            this.Toast.fire({
              icon: 'error',
              title: error.error.detail
            })
          }
        });
      }
    });
  }

}