import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';
import { Location } from '@angular/common';
import { Socket } from 'ngx-socket-io';
import { SwUpdate } from '@angular/service-worker';

declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  hide: boolean = false;
  companyName: any = "";
  socketRoomId: any;
  constructor(private updates: SwUpdate, private location: Location, public translateService: TranslateService, private router: Router,
     private route: ActivatedRoute, private bnIdle: BnNgIdleService) {

    this.updates.available.subscribe(event => {
      this.updates.activateUpdate().then(() => document.location.reload())
    })


    this.companyName = JSON.parse(localStorage.getItem("companyname"));
    this.socketRoomId = JSON.parse(localStorage.getItem("socketroom"));
    translateService.addLangs(['en', 'tl']);
    translateService.setDefaultLang('en');
    const browserLang = translateService.getBrowserLang();
    translateService.use(browserLang.match(/tl|tl-TL/) ? 'tl' : 'en');
  }

  ngOnInit() {
    if (this.companyName) {
      this.bnIdle.startWatching(900).subscribe((res) => {
        if (res) {
          localStorage.clear();
          this.location.go("/");
          window.location.reload();
        }
      })
     
      //   socket.emit('join', {socketroomid:100});
      //   socket.on('chat-message', data => {
      //   console.log("****",data);
      
      // })
     

    }
  }
  ngDoCheck() {
    let x = this.router.url;
    let params = x.split('?')[0];
    if (params == '/signin' || params == '/signup' || params == '/forgot' || params == '/resets' || params == '/home' || params == '/verify' || params == '/verified') {
      this.hide = true;
    } else {
      this.hide = false;
    }
   
  }
}
