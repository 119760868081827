import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(document).ready(function () {
      $(window).scroll(function () {
        // //console.log("log");
        if ($(this).scrollTop() > 20) {
          $("#scroll-top").fadeIn();
          $("#scroll-top").addClass("show");
        } else {
          $("#scroll-top").fadeOut();
          $("#scroll-top").removeClass("show");
        }

        // var scroll = $(this).scrollTop();
        // $(".parallax2").css('background-position','center ' +(scroll*0.75)+'px')
      });

      $("#scroll-top").click(function () {
        $('html,body').animate({ scrollTop: 0 }, 800);
      });

    });


    
  }

}
