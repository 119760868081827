import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Observer, fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  serverUrl = environment.serverUrl;
  constructor(private http: HttpClient) {
  }
  // Check online
  createOnline$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
  }

  signIn(req): Observable<any> {
    return this.http.post(this.serverUrl + `/user-auth`, req,
      { observe: 'response' as 'body' })
      .pipe(map(user => {
        return JSON.stringify(user);
      }));
  }
  // signin(req): Observable<any> {
  //   return this.http.post(this.serverUrl + 'login', req)
  // }
  // forgotPassword(req): Observable<any> {
  //   return this.http.post(this.serverUrl + `password/forgot`, req);
  // }
  // verifiedUser(req): Observable<any> {
  //   return this.http.post(this.serverUrl + `signup/activation`, req);
  // }
}
