import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.scss']
})
export class GetStartedComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit() {

  }

  addSSLProvider(isSelectedSSL) {
    this.router.navigate(['/setup/addsso', isSelectedSSL]);
    localStorage.setItem('selected-sso', `/setup/addsso/${isSelectedSSL}`);
  }


}
