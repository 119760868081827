import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigator',
  templateUrl: './navigator.component.html',
  styleUrls: ['./navigator.component.scss']
})
export class NavigatorComponent implements OnInit {
  roleId: any;

  constructor(private router:Router) { 
    this.roleId = JSON.parse(localStorage.getItem('roleid'));
    if(this.roleId == 'user'){
      this.router.navigate(['/dashboard']);
    }else if(this.roleId == 'admin'){
      this.router.navigate(['/admin']);
    }else{
      this.router.navigate(['/signin']);
    }
  }

  ngOnInit() {
  }

}
