// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // serverUrl: "http://localhost:3001",
  serverUrl: "https://va-api.navan.ai",
  appVersion: "0.0.4"
};


