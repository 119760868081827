import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SigninComponent } from './core/signin/signin.component';

import { BnNgIdleService } from 'bn-ng-idle';

import { GetStartedComponent } from './dashboard/get-started/get-started.component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { SocialLoginModule, AuthServiceConfig, LoginOpt } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import { environment } from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';

import { NavigatorComponent } from './dashboard/navigator/navigator.component';
import { ErrorComponent } from './shared/error/error.component';
import { NgxPaginationModule } from 'ngx-pagination';

const googleLoginOptions: LoginOpt = {
  scope: 'profile email'
};

let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("873289044250-96j2dmhmstorlckd4sp67sb0dna6krce.apps.googleusercontent.com", googleLoginOptions)
    // 873289044250-96j2dmhmstorlckd4sp67sb0dna6krce.apps.googleusercontent.com
    // 873289044250-96j2dmhmstorlckd4sp67sb0dna6krce.apps.googleusercontent.com 

  },

]);

export function provideConfig() {
  return config;
}

const sio: SocketIoConfig = { url: 'https://queue.navan.ai', options: {} };

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    SigninComponent,
    NavigatorComponent,
    GetStartedComponent,
    ErrorComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    HttpClientModule,
    SocketIoModule.forRoot(sio),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    SocialLoginModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgxPaginationModule
  ],
  providers: [
    {
      provide: AuthServiceConfig,

      useFactory: provideConfig,

    },

    BnNgIdleService],
  bootstrap: [AppComponent]
})
export class AppModule { }