import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SigninComponent } from './core/signin/signin.component';
import { AuthGuard } from './guards/authguard.guard';
import { NavigatorComponent } from './dashboard/navigator/navigator.component';
import { ErrorComponent } from './shared/error/error.component';

// import { AddCameraComponent } from './setup/add-camera/add-camera.component';
// import { CamerasListComponent } from './setup/cameras-list/cameras-list.component';
// import { PipelineComponent } from './pipeline/pipeline.component';

const routes: Routes = [
  { path: '', canActivate: [AuthGuard], loadChildren: 'src/app/dashboard/dashboard.module#DashboardModule' },
  { path: 'pipeline', canActivate: [AuthGuard], loadChildren: 'src/app/pipeline/pipeline.module#PipelineModule' },
  // {path:  'camera-list', loadChildren: 'src/app/setup/cameras-list/cameras-list.module#CamerasListModule' },
  // {path:  'add-camera', loadChildren: 'src/app/setup/add-camera/add-camera.module#AddCameraModule' },
  // {path:  'AddCamera', component: AddCameraComponent},
  { path: 'dashboard', loadChildren:'src/app/dashboard/dashboard.module#DashboardModule' },
  { path: 'signin', component: SigninComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'resets', loadChildren: 'src/app/reset-password/reset.module#ResetModule' },
  { path: 'live', loadChildren: 'src/app/live-stream/live-stream/live-stream.module#LiveStreamModule' },
  // { path: '**',pathMatch   : 'full', component: ErrorComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
