import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbDropdownConfig, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { CommonService } from '../common/common.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';
// import { CamerasListComponent } from './setup/cameras-list/cameras-list.component';

import { AuthService, SocialUser, GoogleLoginProvider } from 'angularx-social-login';
import { PipelineService } from '../pipeline/pipeline.service';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavbarComponent implements OnInit {
  @ViewChild('content', { static: true }) public content: NgbModalRef;
  @ViewChild('bar', { static: true }) bar: ElementRef;
  public sidebarOpened = false;
  changepwForm: FormGroup;
  userToken: any;
  isSubmitted: boolean = false;
  firstName: any;
  closeResult: string;
  sidebarToggStatus = 'close';
  isActive: any;
  user: any;
  isTrueSignout: any;

  constructor(private renderer: Renderer2, private location: Location, config: NgbDropdownConfig, private service: CommonService, public translateService: TranslateService, private router: Router, private fb: FormBuilder,
    private modalService: NgbModal, private authService: AuthService, private pipeService: PipelineService) {
    config.placement = 'bottom-right';
    this.pipeService.updatedDataSelection('pipeline');
    this.isActive = 'pipeline';
    this.firstName = JSON.parse(localStorage.getItem("username"));
    this.sidebarToggStatus = localStorage.getItem("sidebar-togg-status");
    this.authService.authState.subscribe((user) => {
      try {
        if (user) {
          if (user.photoUrl) {
            this.user = user.photoUrl;
          }
        }
      } catch (err) {
        console.log("user not there")
      }


    })
    this.pipeService.data.subscribe(data => {
      this.isActive = data;
      localStorage.setItem('menu', data)
    })
    let menuName = localStorage.getItem('menu')
    if (menuName == null) {
      this.isActive = 'pipeline';
    } else {
      this.isActive = menuName;

    }
  }
  ngOnInit() {
    this.changepwForm = this.fb.group({
      "crntpw": new FormControl("", Validators.required),
      "newpw": new FormControl("", Validators.required)
    })
  }


  // @HostListener('document:click', ['$event'])
  // clickout(event) {
  //   if (!this.bar.nativeElement.contains(event.target)) {
  //     document.querySelector('.sidebar-offcanvas').classList.remove('active');
  //   }
  // }

  toggleOffcanvas() {
    // this.renderer.listen('window', 'click',(e:Event)=>{
    let div = document.querySelector('.sidebar-offcanvas');
    if (div.classList.contains('active')) {
      document.querySelector('.sidebar-offcanvas').classList.remove('active');
    } else {
      document.querySelector('.sidebar-offcanvas').classList.add('active');
    }
    //  });

    // if(this.sidebarToggStatus == 'close'){
    //   this.sidebarOpened = this.sidebarToggStatus == 'close'?true:false;
    // }else{
    //   this.sidebarOpened = this.sidebarToggStatus == 'open'?false:true;
    // }
    // if (this.sidebarOpened) {
    //   document.querySelector('.sidebar-offcanvas').classList.add('active');
    // }
    // else {
    //   document.querySelector('.sidebar-offcanvas').classList.remove('active');
    // }
  }
  switchLanguage(language: string) {
    this.translateService.use(language);
    console.log(this.translateService.use(language));
  }

  // Getting form errors
  get errorControl() {
    return this.changepwForm.controls;
  }

  // Change password
  public onChangePassword() {
    this.isSubmitted = true;
    if (!this.changepwForm.valid) {
      return false;
    } else {
      let req = {
        "old_password": this.changepwForm.value.crntpw,
        "new_password": this.changepwForm.value.newpw
      }
      this.service.changePassword(req).subscribe(result => {
        console.log("Reg Response");
        console.log(result);
        var resultdata: any;
        resultdata = result;
        if (resultdata.data.code == 200) {
          Swal.fire({ text: "Password has been changed successfully!", icon: "success", timer: 5000 });
          this.modalService.dismissAll();
        } else {
          Swal.fire({ text: "Password updation failed!", icon: "error", timer: 5000 });
          this.modalService.dismissAll();
        }
        return true;
      });
    }
  }
  openModal() {
    this.open(this.content);
  }
  open(content) {
    this.modalService.dismissAll();
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  closeModal() {
    this.modalService.dismissAll();
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  signOut() {
    localStorage.clear();
    this.router.navigate(['/signin']);
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }
  // signOut(): any{
  //   this.authService.signOut();
  // }
  videolist() {
    // localStorage.setItem('username', JSON.stringify(this.signinForm.value.userid));
    // this.router.navigate(['/video-list']);
    localStorage.setItem('menu', 'upload')
    this.isActive = 'upload';
    this.pipeService.updatedDataSelection('upload');
  }
  home() {
    this.isActive = 'pipeline';
    localStorage.setItem('menu', 'pipeline')
    this.router.navigate(['/pipeline'])
    this.pipeService.updatedDataSelection('pipeline');
  }

}
