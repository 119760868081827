import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  roleId: any;
  sidebarToggler: any;
  sidebarToggStatus: any;
  constructor(private location: Location) {
    this.roleId = JSON.parse(localStorage.getItem('roleid'));
  }

  ngOnInit() {

  }
  toggler(tog) {
    var sidebar_nav = document.getElementById("sidebar");
    if (tog == '') {
      this.sidebarToggStatus = localStorage.setItem('sidebar-togg-status', 'close');
      sidebar_nav.classList.remove("active");
      // sidebar_nav.classList.add("header-sticky");
    }
    this.sidebarToggler = tog;
  }
  signOut() {
    localStorage.clear();
    this.location.go("/");
    window.location.reload();
  }
}
