import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
@Injectable({
  providedIn: 'root'
})
export class PipelineService {
  serverUrl = environment.serverUrl;

  // url: string;
  private dataSource = new BehaviorSubject<any>('');
  data = this.dataSource.asObservable();
  constructor(private http: HttpClient, private router: Router) {
    // this.url = "https://crm-odoo-2020.ue.r.appspot.com/upload-video";

  }
  updatedDataSelection(data) {
    console.log("**",data)
    this.dataSource.next(data);
  }
  createPipe(req): Observable<any> {
    return this.http.post(`${this.serverUrl}/pipeline/add`, req);
  }

  getPipe(company_id): Observable<any> {
    return this.http.get(`${this.serverUrl}/pipeline/${company_id}`);
  }
  getVideos(company_id): Observable<any> {
    return this.http.get(`${this.serverUrl}/videos/${company_id}`);
  }
  getModels(): Observable<any> {
    return this.http.get(`${this.serverUrl}/pipeline/model`);
  }
  uploadVideo(req): Observable<any> {
    return this.http.post(`${this.serverUrl}/upload-video`, req);
  }
  getOutputVideoResult(_id): Observable<any> {
    return this.http.get(`${this.serverUrl}/output-video-result/${_id}`);
  }
  deleteVideo(id): Observable<any> {
    return this.http.delete(`${this.serverUrl}/input-video/${id}`);
  }
  deletePipe(id): Observable<any> {
    return this.http.delete(`${this.serverUrl}/pipeline/detail/${id}`);
  }
}
