import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  userToken: any;
  serverUrl = environment.serverUrl;
  constructor(private http: HttpClient) {
    this.userToken = JSON.parse(localStorage.getItem('usertoken'));
    
   }

  changePassword(req): Observable<any> {
    const httpOptions = {
      headers: {
        "Authorization":this.userToken,
        "Content-Type": "application/json"
      },
      params: new HttpParams()
    };
    return this.http.post(this.serverUrl + `password/change`, req, httpOptions)
  }
}
