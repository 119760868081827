import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate {
  roleId: any;
  stateUrl: any;
  userUrl = [];
  constructor(private router: Router) { }

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    var patientName = JSON.parse(localStorage.getItem("username"));

    if (typeof patientName != "undefined" && patientName != null) {
      return true;
    }
    this.router.navigate(["/signin"], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
